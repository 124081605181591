import 'element-ui/lib/theme-chalk/index.css';
import 'tippy.js/dist/tippy.css';
import './assets/css/app.scss';
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import './plugins/ckeditor'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueScrollTo from "vue-scrollto";
import VueCryptojs from 'vue-cryptojs';
import 'element-ui/lib/theme-chalk/display.css';
import elTableInfiniteScroll from 'el-table-infinite-scroll';
// import VeeValidate from 'vee-validate'

// import { ErrorService } from "./utils/ErrorService";
Vue.prototype.$bus = new Vue();


import {
  DataTables,
  DataTablesServer
} from 'vue-data-tables'

import App from './App.vue'
import ElementUI from 'element-ui';

import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import VueHtmlToPaper from "vue-html-to-paper";
import VueSignaturePad from 'vue-signature-pad';
import locale from 'element-ui/lib/locale/lang/en'
import router from "@/router"
import store from "@/store"
import vueMoment from 'vue-moment'
import "@/helpers/filters"
import GoogleAPIs from 'vue-googleapis'
import vClickOutside from 'v-click-outside'
import DlgDraggable from "vue-element-dialog-draggable";
import Vidle from 'v-idle';
import VueFriendlyIframe from 'vue-friendly-iframe';

import VueFormulate from '@braid/vue-formulate'
import FormulatePercentage from "./components/templates/formComponentsExecute/PercentageField.vue";
import VueMeta from 'vue-meta'
import DialogComponent from './components/customComponents/CustomDialog.vue'
import IconsList from "./layouts/IconsList.vue"
import Icons from "./layouts/Icons.vue"
import '../src/assets/css/global.css';
import Dialer from "./views/common/Dialer.vue"
import PhoneCallModal from './views/common/PhoneCallModal.vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VCreditCard from 'v-credit-card';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import SocketPlugin from './socket';

Vue.use(VueFriendlyIframe);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('v-credit-card', VCreditCard);
Vue.use(DlgDraggable, {
  containment: true //Constrains dragging to within the bounds of the window.  Default: false.
});
Vue.use(VueScrollTo);
Vue.use(VueDraggableResizable);
Vue.use(elTableInfiniteScroll);
Vue.use(SocketPlugin);
// Vue.use(PrimeVue);
Vue.use(vClickOutside);
Vue.component("FormulatePercentage", FormulatePercentage);
Vue.component("DialogComponent", DialogComponent);
Vue.component("IconsList",IconsList);
Vue.component("Icons",Icons);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component("Dialer",Dialer);
Vue.component('PhoneCallModal', PhoneCallModal);

// Vue.use(VeeValidate);
Vue.use(VueFormulate, {
  library: {
    percentage: {
      classification: "text",
      component: "formulate-percentage"
    }
  },
});

// Handle all Vue errors
// Vue.config.errorHandler = (error) => ErrorService.onError(error);
// Vue.config.warnHandler =  (warn) => ErrorService.onWarn(warn);

function generateUniqueValue() {
  return Date.now(); // Using current timestamp
}

const cssUrl = `${process.env.BASE_URL}global.css?v=${generateUniqueValue()}`;

const linkElement = document.createElement('link');
linkElement.setAttribute('rel', 'stylesheet');
linkElement.setAttribute('href', cssUrl);
document.head.appendChild(linkElement);

const options = {
  "name": "_blank",
  "specs": [
    "fullscreen=yes",
    "titlebar=yes",
    "scrollbars=yes"
  ],
  "styles": [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css",
    "path/to/font-awesome/css/font-awesome.min.css"
  ]
};
Vue.use(VueHtmlToPaper, options);
Vue.use(VueSignaturePad);
Vue.use(ElementUI, {
  locale
})
Vue.use(DataTables)
Vue.use(DataTablesServer)
Vue.use(vueMoment);
Vue.use(VueSweetalert2);
// Vue.use(VOffline);
const CLIENT_ID = '689727011138-vtf8fnjjgcpbluamb2kk8cejvrt3ri0c.apps.googleusercontent.com';
const API_KEY = 'AIzaSyByrdgNgrLHASHGaeqX75wLs9fN9zlC7XY';

const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"];
const SCOPES = 'https://www.googleapis.com/auth/contacts.readonly';
Vue.use(GoogleAPIs, {
  apiKey: API_KEY,
  clientId: CLIENT_ID,
  discoveryDocs: DISCOVERY_DOCS,
  scope: SCOPES
})
Vue.config.productionTip = false;

export const bus = new Vue();
Vue.use(VueMeta);
Vue.prototype.$eventBus = new Vue();
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

import NotificationHelper from '@/mixins/NotificationHelper'

Vue.mixin(NotificationHelper);
// Vue.use(Meta, {
//   keyName: 'metaInfo',
//   attribute: 'data-vue-meta',
//   ssrAttribute: 'data-vue-meta-server-rendered',
//   tagIDKeyName: 'vmid',
//   refreshOnceOnNavigation: true
// });
Vue.use(VueCryptojs);
Vue.use(Vidle);

// Add global method for showing the PhoneNumber component
Vue.prototype.$showMailCompose = function (mail,conversationId=null) {
  console.log("Mail Function called",{email:mail,conversationId:conversationId});
  this.$eventBus.$emit("showMailComposer",{email:mail,conversationId:conversationId});
};
Vue.prototype.$showPhoneNumberDialog = function (phoneNumber) {
  this.$eventBus.$emit("show-phone-number",phoneNumber);
};
Vue.prototype.$showCalendarEvent = function (event) {
  this.$eventBus.$emit("showCalendarEvent",event);
};