import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

export const fetchAndAddIconsToLocal = async () => {
  let icons = await fetchAllIcondFromDatabase();
  return await addIconsToLocal(icons);
};

export const addIconsToLocal = async (icons) => {
  return await db.icons.bulkAdd(
    icons.map((d) => {
      d.createdAt = new Date().toISOString();
      return d;
    })
  );
};
export const addSingleIcon = async (data) => {
  try{
    if(data?.name && data?.content){
      let icon = await fetchIconFromLocal(data.name);
      if(!icon){
        return db.icons.add({
          ...data,
          ...{ createdAt: new Date().toISOString() },
        });
      }
    }
  }catch(e){
    console.log("addSingleIcon", e);
  }
}

//fetch all icons
export const fetchAllIcons = async () => {
  let icons;
  icons = await fetchAllIconsFromLocal();
  if (!icons.length) {
    icons = await fetchAllIcondFromDatabase();
    await addIconsToLocal(icons);
  }
  return icons;
};

export const fetchAllIconsFromLocal = () => {
  return db.icons.toArray();
};

export const fetchAllIcondFromDatabase = async () => {
  const result = await postAPICall("GET", `/icons/getAllIcons`);
  return result.data;
};

//fetch single icon
export const fetchIconByName = async (name) => {
  let icon;
  icon = await fetchIconFromLocal(name);
  // if (!icon) {
  //   icon = await fetchIconFromDatabase(name);
  // }
  return icon;
};

export const fetchIconFromLocal = async (name) => {
  try {
    let result = await db.icons.get({ name: name });
    if (!result) {
      const appCaseName = name.replace(/-/g, '-').replace(/^\w/, c => c.toUpperCase());
      result = await db.icons.get({ name: appCaseName });
    }
    return result;
  } catch (error) {
    console.error('Error fetching icon:', error);
    return null;
  }
};
export const fetchIconFromDatabase = async (name) => {
  const result = await postAPICall("GET", `/icons/byName/${name}`);
  addSingleIcon(result.data);
  return result.data;
};
