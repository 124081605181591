<template>
  <div>
    <el-dialog title="Create Event" :visible.sync="dialogVisible" width="60%"  :before-close="closeModal"
    :close-on-press-escape="false">
      <!-- Select Calendar -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-select
            v-model="selectedCalendar"
            placeholder="Select Calendar"
            style="width: 100%"
            size="mini"
          >
            <el-option
              v-for="calendar in calendars"
              :key="calendar.id"
              :label="calendar.name"
              :value="calendar.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-select
            v-model="selectedTimezone"
            placeholder="Select Timezone"
            style="width: 100%"
            size="mini"
          >
            <el-option
              v-for="(timezone, index) of time_zones"
              :key="index"
              :label="timezone"
              :value="timezone"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- Event Title -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <el-input
            v-model="eventForm.title"
            placeholder="Event Title"
            size="mini"
          ></el-input>
        </el-col>
      </el-row>
      <!-- Attendees Section -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <div class="attendees-group" @click="focusInput('attendeeInput')">
            <!-- Display Attendees as Tags -->
            <el-tag
              v-for="(email, index) in eventForm.attendees"
              :key="'attendee-' + index"
              closable
              @close="removeAttendee(index)"
              type="success"
              size="mini"
              style="margin-right: 5px; cursor: pointer"
              @keydown.backspace="removeAttendee(index)"
              tabindex="0"
            >
              {{ email }}
            </el-tag>

            <!-- Editable Span for Typing -->
            <span
              ref="attendeeInput"
              contenteditable="true"
              class="editable-span"
              @keydown.enter.prevent="addAttendee"
              @blur="addAttendee"
            ></span>
          </div>
          <p v-if="attendeeError" class="error-message">{{ attendeeError }}</p>
        </el-col>
      </el-row>

      <!-- Optional Attendees Checkbox -->
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :span="24">
          <el-checkbox v-model="includeOptionalAttendees">
            Add Optional Attendees
          </el-checkbox>
        </el-col>
      </el-row>

      <!-- Optional Attendees Section -->
      <el-row
        v-if="includeOptionalAttendees"
        :gutter="20"
        style="margin-top: 10px"
      >
        <el-col :span="24">
          <div
            class="attendees-group"
            @click="focusInput('optionalAttendeeInput')"
          >
            <!-- Display Optional Attendees as Tags -->
            <el-tag
              v-for="(email, index) in eventForm.optionalAttendees"
              :key="'optional-' + index"
              closable
              @close="removeOptionalAttendee(index)"
              type="success"
              style="margin-right: 5px; cursor: pointer"
              @keydown.backspace="removeAttendee(index)"
              tabindex="0"
            >
              {{ email }}
            </el-tag>

            <!-- Editable Span for Typing -->
            <span
              ref="optionalAttendeeInput"
              contenteditable="true"
              class="editable-span"
              @keydown.enter.prevent="addOptionalAttendee"
              @blur="addOptionalAttendee"
            ></span>
          </div>
          <p v-if="optionalAttendeeError" class="error-message">
            {{ optionalAttendeeError }}
          </p>
        </el-col>
      </el-row>

      <!-- Event Description -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <div
            v-loading="ckeditorLoading"
            :element-loading-text="loadingText"
            style="margin-top: 10px"
            size="mini"
          >
            <ckeditor
              v-if="isOpened"
              v-model="eventForm.description"
              :config="editorConfig"
              :key="editorKey"
            ></ckeditor>
          </div>
        </el-col>
      </el-row>

      <!-- Start Date & Time -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="8">
          <el-date-picker
            v-model="eventForm.startDateTime"
            type="datetime"
            placeholder="Start Date & Time"
            style="width: 100%"
            size="mini"
          />
        </el-col>

        <!-- End Date & Time -->
        <el-col :span="8">
          <el-date-picker
            v-model="eventForm.endDateTime"
            type="datetime"
            placeholder="End Date & Time"
            style="width: 100%"
            size="mini"
          />
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="eventForm.reminder"
            placeholder="Reminder"
            style="width: 100%"
            size="mini"
          >
            <el-option
              v-for="time in reminderOptions"
              :key="time.value"
              :label="time.label"
              :value="time.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- Time Zone Selection -->
      <!-- <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <div>
            <label for="timezone">Select Timezone:</label>
            <vue-timezone-select
              v-model="selectedTimezone"
              :placeholder="'Select Timezone'"
              :searchable="true"
            />
          </div>
        </el-col>
      </el-row> -->

      <!-- Footer Buttons -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal" size="mini">Cancel</el-button>
        <el-button type="primary" @click="createEvent" size="mini"
          >Create</el-button
        >
      </span>
    </el-dialog>

    <el-button type="primary" @click="dialogVisible = true"
      >Create Event</el-button
    >
  </div>
</template>

<script>
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";

// import VueTimezoneSelect from "vue-timezone-select";

import { configs } from "@/assets/data/schedule_config.ts";

const timezonesArray = configs.timezonesArray;
//import "vue-timezone-selector/dist/vue-timezone-selector.css";

export default {
  data() {
    return {
      dialogVisible: true,
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorKey: 0,
      selectedCalendar: "",
      calendars: [
        { id: 1, name: "Work" },
        { id: 2, name: "Personal" },
      ],
      eventForm: {
        title: "",
        description: "",
        startDateTime: "",
        endDateTime: "",
        timezone: "UTC",
        reminder: "",
        attendees: [],
        optionalAttendees: [],
      },
      newAttendee: "",
      newOptionalAttendee: "",
      attendeeError: "",
      optionalAttendeeError: "",
      includeOptionalAttendees: false,
      reminderOptions: [
        { value: "5", label: "5 minutes before" },
        { value: "10", label: "10 minutes before" },
        { value: "15", label: "15 minutes before" },
        { value: "30", label: "30 minutes before" },
        { value: "60", label: "1 hour before" },
      ],
      ckeditorLoading: false, // Add this
      loadingText: "Loading editor...", // Add this
      isOpened: true, // Add this, assuming you want CKEditor to load immediately
      selectedTimezone: "", // Add this, assuming default timezone
      time_zones: timezonesArray,
    };
  },
  methods: {
    // Add attendee to list
    addAttendee(event) {
      const input = event.target.innerText.trim();
      if (!this.validateEmail(input)) {
        this.attendeeError = "Invalid email format.";
        return;
      }
      if (input && !this.eventForm.attendees.includes(input)) {
        this.eventForm.attendees.push(input);
        this.attendeeError = "";
      }
      event.target.innerText = ""; // Clear input field
    },
    removeAttendee(index) {
      this.eventForm.attendees.splice(index, 1);
    },
    addOptionalAttendee(event) {
      const input = event.target.innerText.trim();
      if (!this.validateEmail(input)) {
        this.optionalAttendeeError = "Invalid email format.";
        return;
      }
      if (input && !this.eventForm.optionalAttendees.includes(input)) {
        this.eventForm.optionalAttendees.push(input);
        this.optionalAttendeeError = "";
      }
      event.target.innerText = ""; // Clear input field
    },
    removeOptionalAttendee(index) {
      this.eventForm.optionalAttendees.splice(index, 1);
    },
    focusInput(refName) {
      this.$refs[refName].focus();
    },

    // Validate Email Format
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    closeModal() {
      // this.isModalOpen = false;
      this.dialogVisible = false;
      //bus emit
      this.$eventBus.$emit("hide-Calendar-Event", () => {
        this.isCalendarEventVisible = false;
      });
    },
    async createEvent() {
      const eventData = {
        title: this.eventForm.title,
        description: this.eventForm.description,
        start: this.eventForm.startDateTime,
        end: this.eventForm.endDateTime,
        timezone: this.eventForm.timezone,
        reminder: this.eventForm.reminder,
        attendees: this.eventForm.attendees,
        optionalAttendees: this.eventForm.optionalAttendees,
      };

      try {
        const response = await this.$http.post("/api/events", eventData);
        if (response.status === 201) {
          this.$message.success("Event created successfully!");
          this.dialogVisible = false;
          this.resetForm();
        }
      } catch (error) {
        this.$message.error("Failed to create event.");
        console.error(error);
      }
    },
    resetForm() {
      this.eventForm = {
        title: "",
        description: "",
        startDateTime: "",
        endDateTime: "",
        timezone: "UTC",
        reminder: "",
      };
    },
    handleKeyPress(event) {
      if (event.shiftKey && event.key === "E") {
        this.dialogVisible = true;
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style scoped>
.attendees-group {
  border: 1px solid #dcdfe6;
  padding: 5px;
  min-height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: text;
}

.editable-span {
  min-width: 50px;
  padding: 5px;
  outline: none;
  border: none;
  display: inline-block;
  white-space: nowrap;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
